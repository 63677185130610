import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/endPoints';

class AuthService {
  static logInFlorist(data) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.login),
      data,
    });
  }

  static setMsLoginAuth(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.loginMS, ...extraParams),
    });
  }

  static validateSession(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.session, ...extraParams),
    });
  }

  static getCountryCodes() {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.country),
    });
  }
}
export default AuthService;

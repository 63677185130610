import { history, convertObjectIntoQueryParams } from '../../constants/helpers';
//actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/appActions';
//api
import OrdersListService from '../../services/OrdersListService';
//action types
import { SET_IS_SEARCHING_FALSE, SET_IS_SEARCHING_TRUE } from './ordersActionTypes';
//selectors
import { getOrdersIsSearching } from './ordersListSelectors';

export const setIsSearchingTrue = () => ({ type: SET_IS_SEARCHING_TRUE });

export const setIsSearchingFalse = () => ({ type: SET_IS_SEARCHING_FALSE });

export const setOrdersListData = (params, slotId) => async (dispatch, getState) => {
	const state = getState(),
		isSearching = getOrdersIsSearching({ state });
	dispatch(setLoaderOpenTrue());
	try {
		const res = await OrdersListService.getordersListData(params, slotId);
		dispatch({
			type: 'SET_ORDERS_LIST_DATA',
			payload: {
				ordersList: res.data.data,
				pageCount: res.data.pageCount,
				isFlorist: params.floristId == -1 ? false : true,
				searchKey: params.searchKey,
			},
		});
		history.push({
			pathname: '/orders',
			search: convertObjectIntoQueryParams(params),
		});
		dispatch(setLoaderOpenFalse());
		if (isSearching) {
			dispatch(setIsSearchingFalse());
		}
	} catch (err) {
		dispatch(setLoaderOpenFalse());
	}
};

export const setGroupBySkuData = (params) => async (dispatch) => {
	dispatch(setLoaderOpenTrue());
	try {
		const res = await OrdersListService.getGroupedBySku(params);
		dispatch({
			type: 'SET_GROUP_BY_SKU_DATA',
			payload: res.data.data,
		});
		dispatch(setLoaderOpenFalse());
	} catch (e) {
		dispatch(setLoaderOpenFalse());
	}
};

export const setGroupByZoneData = (params) => async (dispatch) => {
	dispatch(setLoaderOpenTrue());
	try {
		const res = await OrdersListService.getGroupedByZone(params);
		dispatch({
			type: 'SET_GROUP_BY_ZONE_DATA',
			payload: res.data.data,
		});
		dispatch(setLoaderOpenFalse());
	} catch (e) {
		dispatch(setLoaderOpenFalse());
	}
};

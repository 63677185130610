import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//ionic
import { IonLoading } from '@ionic/react';
//components
import OrderDetailsHeader from '../../components/orderDetails/OrderDetailsHeader';
import BySkuComponent from '../../components/groupedBySku/BySkuComponent';
//constants
import { convertQueryParamsIntoObject } from '../../constants/helpers';
//actions
import { setGroupBySkuData } from '../../store/orders/ordersListActions';
import { setLoaderOpenFalse } from '../../store/app/appActions';
//selectors
import { getGroupBySkuData } from '../../store/orders/ordersListSelectors';
import { getIsLoaderOpen } from '../../store/app/appSelectors';

const GroupedBySku = ({ location, history }) => {
  const groupBySkuData = useSelector((state) => getGroupBySkuData({ state })),
    isLoaderOpen = useSelector((state) => getIsLoaderOpen({ state })),
    dispatch = useDispatch(),
    [params, setParams] = useState('');

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setParams(queryObj);
      dispatch(setGroupBySkuData(queryObj));
    }
  }, []);

  const onStatusChanged = (index, newStatus, subIndex) => {
    var items = [...groupBySkuData];
    items[index].ordersList[subIndex].preparationStatus = newStatus;
    dispatch({
      type: 'SET_GROUP_BY_SKU_DATA',
      payload: items,
    });
  };

  return (
    <div style={{ backgroundColor: '#dadada' }}>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={isLoaderOpen}
        onDidDismiss={() => dispatch(setLoaderOpenFalse())}
        message={'Please wait...'}
      />
      {params && <OrderDetailsHeader initialParams={params} history={history} />}
      <BySkuComponent
        history={history}
        onStatusChanged={onStatusChanged}
        groupBySkuData={groupBySkuData}
      />
    </div>
  );
};

export default GroupedBySku;

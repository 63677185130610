import { updateObject } from '../utility';

const initialState = {
	isLoggedIn: !!localStorage.getItem('token'),
	isLoaderOpen: false,
	notificationsList: [],
	notificationsLength: 0,
	isNotificcationAlertOpen: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_IS_LOGGED_IN_TRUE': {
			return updateObject(state, { isLoggedIn: true });
		}
		case 'SET_IS_LOGGED_IN_FALSE': {
			localStorage.removeItem('opsId');
			return updateObject(state, { isLoggedIn: false });
		}
		case 'SET_LOADER_OPEN_TRUE': {
			return updateObject(state, { isLoaderOpen: true });
		}
		case 'SET_LOADER_OPEN_FALSE': {
			return updateObject(state, { isLoaderOpen: false });
		}
		case 'SET_NOTIFICATIONS_LIST': {
			return updateObject(state, { notificationsList: action.payload });
		}
		case 'SET_NOTIFICATIONS_LENGTH': {
			return updateObject(state, { notificationsLength: action.payload });
		}
		case 'SET_TOGGLE_NOTIFICATIONS_ALERT': {
			return updateObject(state, { isNotificcationAlertOpen: !state.isNotificcationAlertOpen });
		}
		default:
			return state;
	}
};

export default reducer;

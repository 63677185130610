import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//ionic
import { IonLoading } from '@ionic/react';
//components
import OrderDetailsHeader from '../../components/orderDetails/OrderDetailsHeader';
import ByZoneComponent from '../../components/groupedByZone/ByZoneComponent';
//constants
import { convertQueryParamsIntoObject } from '../../constants/helpers';
//actions
import { setGroupByZoneData } from '../../store/orders/ordersListActions';
import { setLoaderOpenFalse } from '../../store/app/appActions';
//selectors
import { getGroupByZoneData } from '../../store/orders/ordersListSelectors';
import { getIsLoaderOpen } from '../../store/app/appSelectors';

const GroupedByZone = ({ location, history }) => {
  const groupByZoneData = useSelector((state) => getGroupByZoneData({ state })),
    isLoaderOpen = useSelector((state) => getIsLoaderOpen({ state })),
    dispatch = useDispatch(),
    [params, setParams] = useState('');

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setParams(queryObj);
      dispatch(setGroupByZoneData(queryObj));
    }
  }, []);

  const onStatusChanged = (index, newStatus, subIndex) => {
    var items = [...groupByZoneData];
    items[index].ordersList[subIndex].preparationStatus = newStatus;
    dispatch({
      type: 'SET_GROUP_BY_ZONE_DATA',
      payload: items,
    });
  };

  return (
    <div style={{ backgroundColor: '#dadada' }}>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={isLoaderOpen}
        onDidDismiss={() => dispatch(setLoaderOpenFalse())}
        message={'Please wait...'}
      />
      {params && <OrderDetailsHeader initialParams={params} history={history} />}
      <ByZoneComponent
        history={history}
        onStatusChanged={onStatusChanged}
        groupByZoneData={groupByZoneData}
      />
    </div>
  );
};

export default GroupedByZone;

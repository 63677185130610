
import HomeComponent from "../../components/home/HomeComponent"
import {IonPage} from "@ionic/react";
import './Home.scss';

const Home = () => {
  return (
    <div>
      <HomeComponent />
    </div>
    
  );
};

export default Home;

import React, { Fragment, useEffect, useState } from 'react';
import InventoryTable from './InventoryTable';
import ImagesList from './ImagesList';
import { IonCol } from '@ionic/react';
//api
import OrderDetailsService from '../../services/OrderDetailsService';

const ProductDetails = ({ proId }) => {
	const [inventoryDetails, setInventoryDetails] = useState([]);
	const fetchCartDetails = async () => {
		try {
			const res = await OrderDetailsService.getInventoryDetails(proId);
			setInventoryDetails(res.data);
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(() => {
		fetchCartDetails();
	}, []);

	return (
		<Fragment>
			<IonCol style={{ alignSelf: 'center' }} size="4">
				<ImagesList listOfImages={inventoryDetails.productImage} />
			</IonCol>
			<IonCol size="8">
				<InventoryTable inventoryData={inventoryDetails.inventoryDTO} />
			</IonCol>
		</Fragment>
	);
};

export default ProductDetails;

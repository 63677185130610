import { combineReducers } from "redux";
import app from "./app/appReducer";
import ordersList from "./orders/ordersListReducer";
import totalOrderDetails from "./orderDetails/orderDetailsReducer";

const rootReducer = combineReducers({
  app,
  ordersList,
  totalOrderDetails,
});

export default rootReducer;

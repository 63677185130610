import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { reloadOutline, homeOutline, arrowBackOutline, star } from 'ionicons/icons';
import { reloadPage, colleagueStatus } from '../../constants/helpers';
import moment from 'moment';
import ChangeStatusBtn from '../ordersList/ChangeStatusBtn';
import { handleBack, convertQueryParamsIntoObject } from '../../constants/helpers';
import {
  IonHeader,
  IonToolbar,
  IonIcon,
  IonCard,
  IonRow,
  IonCol,
  IonSearchbar,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import NotificationsComponent from '../shared/NotificationsComponent';
//actions
import { setOrdersListData } from '../../store/orders/ordersListActions';

const OrderDetailsHeader = ({
  orderDetails,
  history,
  details,
  initialParams,
  slotId,
  location,
  deliveryTimeSlot,
  fetchData,
}) => {
  const [searchText, setSearchText] = useState(''),
    dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      setOrdersListData({ ...initialParams, page: 1, floristId: -1, searchKey: searchText }, slotId)
    );
  };

  const handleGroupBy = (e) => {
    const clonedParams = { ...initialParams, groupedBy: e.target.value };
    const queryString =
      '?' +
      Object.keys(clonedParams)
        .map((key) => `${key}=${clonedParams[key]}`)
        .join('&');
    history.push({
      pathname: `/${e.target.value}`,
      search: queryString,
    });
  };

  return (
    <Fragment>
      <IonHeader>
        <IonToolbar style={{ padding: '5px 15px' }}>
          {details && (
            <IonIcon
              style={{ marginRight: 10 }}
              onClick={() => handleBack()}
              slot="start"
              className="home-icons"
              icon={arrowBackOutline}
            />
          )}
          <IonIcon
            onClick={() => history.push('/')}
            slot="start"
            className="home-icons"
            icon={homeOutline}
            style={{ alignSelf: 'center' }}
          />
          {!details && (
            <div style={{ display: 'flex' }}>
              <IonCol
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <h5 style={{ marginLeft: 20 }}>
                  {moment(initialParams.deliveryDate).format('DD/MM/YYYY')}
                </h5>
                <h5 style={{ marginLeft: 10 }}>{deliveryTimeSlot}</h5>
                <form className="search-form" onSubmit={(e) => handleSubmit(e)}>
                  <IonSearchbar
                    className="ion-search-bar"
                    value={searchText}
                    onIonChange={(e) => setSearchText(e.detail.value)}
                    placeholder="Search by order ID or SKU"
                  />
                </form>
              </IonCol>
              <IonCol
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IonItem>
                  <IonLabel>Group by</IonLabel>
                  <IonSelect
                    value={convertQueryParamsIntoObject(location.search).groupedBy}
                    okText="Ok"
                    cancelText="Dismiss"
                    onIonChange={handleGroupBy}
                  >
                    <IonSelectOption value="groupedBySKU">Product SKU</IonSelectOption>
                    <IonSelectOption value="groupedByZone">Delivery Zone</IonSelectOption>
                    <IonSelectOption value="orders">Default</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </div>
          )}
          <NotificationsComponent />
          <IonIcon
            onClick={() => reloadPage()}
            style={{ marginRight: 13, alignSelf: 'center' }}
            slot="end"
            className="home-icons"
            icon={reloadOutline}
          />
        </IonToolbar>
      </IonHeader>
      {orderDetails !== '' && details && (
        <IonCard style={{ padding: 15 }}>
          <IonRow style={{ display: 'flex', alignItems: 'center' }}>
            <IonCol
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h6 style={{ color: '#0c697e' }}>
                {moment(orderDetails.deliveryDate).format('DD/MM/YYYY')}
              </h6>
              <h6 style={{ whiteSpace: 'nowrap' }}>{orderDetails.deliveryTimeName}</h6>
            </IonCol>
            <IonCol>
              <h6 style={{ color: '#0c697e', marginLeft: 7 }}>Order</h6>
              <h6>{orderDetails.orderId}</h6>
              {orderDetails.productionLine === 1 && (
                <IonIcon
                  className="star-outline"
                  icon={star}
                  style={{
                    color: '#DAA520',
                    fontSize: 27,
                    marginLeft: 14,
                  }}
                />
              )}
              {orderDetails.nwDelivery && <div className="nw-order">NW</div>}
            </IonCol>
            {orderDetails.deliveryZone && (
              <IonCol>
                <h6 style={{ color: '#0c697e' }}> Zone</h6>
                <h6> {orderDetails.deliveryZone}</h6>
              </IonCol>
            )}
            {orderDetails.note && (
              <IonCol>
                <h6 style={{ color: '#0c697e' }}>Note</h6>
                <h6 style={{ color: '#DC143C' }}>{orderDetails.note && orderDetails.note}</h6>
              </IonCol>
            )}
            {orderDetails.florist && orderDetails.florist.name && (
              <IonCol className="assign-florist">
                <h6 style={{ textAlign: 'center', marginBottom: 10 }} className="mb-0">
                  {orderDetails.florist.name && orderDetails.florist.name}
                </h6>
                <h6 style={{ textAlign: 'center', color: '#DC143C' }}>
                  {colleagueStatus[orderDetails.preparationStatus]}
                </h6>
              </IonCol>
            )}
            <IonCol style={{ textAlign: 'center' }}>
              <ChangeStatusBtn
                item={orderDetails}
                fetchMoreData={fetchData}
                initialParams={initialParams}
                details={true}
              />
            </IonCol>
          </IonRow>
        </IonCard>
      )}
    </Fragment>
  );
};

export default withRouter(OrderDetailsHeader);

import React, { Fragment, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Lightbox from 'react-image-lightbox';

const ImagesList = ({ listOfImages }) => {
	const [isLightBox, setIsLightBox] = useState(false),
		[clickedImg, setClickedImg] = useState('');
	return (
		<Fragment>
			<div className="slide-container">
				{listOfImages && (
					<Slide>
						{listOfImages.map((el, n) => (
							<div key={n} className="each-slide">
								<img
									onClick={() => {
										setClickedImg(el.image);
										setIsLightBox(true);
									}}
									style={{ width: '100%' }}
									src={el.image}
								/>
							</div>
						))}
					</Slide>
				)}
				{isLightBox && (
					<Lightbox mainSrc={clickedImg} onCloseRequest={() => setIsLightBox(false)} />
				)}
			</div>
		</Fragment>
	);
};

export default ImagesList;

import React, { Fragment, useEffect, useState } from 'react';
import { IonModal, IonLoading } from '@ionic/react';
import Lightbox from 'react-image-lightbox';
//api
import OrderDetailsService from '../../services/OrderDetailsService';

const BasketModal = ({ isModalOpen, setIsOpenModal, basketId }) => {
	const [basketDetails, setBasketDetails] = useState(''),
		[isLightBox, setIsLightBox] = useState(false),
		[clickedImg, setClickedImg] = useState(''),
		[isLoaderOpen, setIsLoaderOpen] = useState(false);

	const fetchBasketDetails = async (basketId) => {
		setIsLoaderOpen(true);
		try {
			const res = await OrderDetailsService.getBasketDetails(basketId);
			setBasketDetails(res.data);
			setIsLoaderOpen(false);
		} catch (err) {
			setIsLoaderOpen(false);
		}
	};
	useEffect(() => {
		fetchBasketDetails(basketId);
	}, []);

	console.log(basketDetails);

	return (
		<IonModal
			isOpen={isModalOpen}
			cssClass="hint-modal"
			swipeToClose={true}
			onDidDismiss={() => setIsOpenModal(false)}
			animated={true}
		>
			<Fragment>
				<IonLoading
					cssClass="my-custom-class"
					isOpen={isLoaderOpen}
					onDidDismiss={() => setIsLoaderOpen(false)}
					message={'Please wait...'}
				/>
				{basketDetails ? (
					<table style={{ width: '100%' }} className="table table-bordernone">
						<thead>
							<tr>
								<th style={{ padding: 25 }} scope="col">
									Item
								</th>
								<th scope="col">Name</th>
								<th scope="col">SKU</th>
								<th scope="col">Qty</th>
							</tr>
						</thead>

						<tbody>
							{basketDetails &&
								basketDetails.map((el, i) => (
									<Fragment key={i}>
										<tr style={{ textAlign: 'center' }}>
											<td>
												<img
													onClick={() => {
														setClickedImg(el.image);
														setIsLightBox(true);
													}}
													src={el.image}
													style={{ width: 80 }}
												/>
											</td>
											<td>{el.name && el.name}</td>
											<td>{el.sku && el.sku}</td>
											<td>{el.qty && el.qty}</td>
										</tr>
										{isLightBox && (
											<Lightbox mainSrc={clickedImg} onCloseRequest={() => setIsLightBox(false)} />
										)}
									</Fragment>
								))}
						</tbody>
					</table>
				) : (
					<p style={{ color: 'red', textAlign: 'center' }}>Empty basket</p>
				)}
			</Fragment>
		</IonModal>
	);
};

export default BasketModal;

import React, { Fragment } from 'react';
import { colleagueStatus } from '../../constants/helpers';
import { star } from 'ionicons/icons';
import { IonCard, IonCol, IonCardContent, IonCardHeader, IonGrid, IonIcon } from '@ionic/react';
import ChangeStatusBtn from '../ordersList/ChangeStatusBtn';

const BySkuComponent = ({ groupBySkuData, history, onStatusChanged }) => {
  return (
    <IonGrid style={{ backgroundColor: '#dadada' }}>
      {groupBySkuData.length > 0 ? (
        <Fragment>
          {groupBySkuData?.map((item, i) => (
            <IonCard className="order-card" key={i}>
              <IonCardHeader className="card-header ">
                <IonCol className="assign-florist">
                  <img alt="" style={{ width: 80, marginRight: 20 }} src={item.productImage} />
                </IonCol>
                <IonCol className="assign-florist">
                  <h6 style={{ marginLeft: 10, color: 'red' }} className="mb-0">
                    SKU {item.productSku}
                  </h6>
                </IonCol>
                <IonCol className="assign-florist">
                  <h6 style={{ marginLeft: 10 }} className="mb-0">
                    {item.productName}
                  </h6>
                </IonCol>
              </IonCardHeader>
              <IonCardContent>
                {item.ordersList?.map((el, n) => (
                  <div key={n} style={{ display: 'flex' }}>
                    <IonCol className="assign-florist">
                      <h6 style={{ marginLeft: 10, fontSize: 16 }} className="mb-0">
                        Order{' '}
                        {el.orderPriority === 1 ? (
                          <span style={{ fontSize: 23, color: 'red' }}>!</span>
                        ) : (
                          ''
                        )}
                      </h6>
                      <h6
                        onClick={() => history.push(`/orderdetails/${el.orderId}`)}
                        style={{ color: 'red', cursor: 'pointer', fontSize: 16 }}
                      >
                        {el.orderId}
                      </h6>
                      {el.productionLine === 1 && (
                        <IonIcon
                          className="star-outline"
                          icon={star}
                          style={{
                            color: '#DAA520',
                            fontSize: 22,
                            marginLeft: 18,
                          }}
                        ></IonIcon>
                      )}
                      {el.membershipType === 1 && <div className="vip-order">VIP</div>}
                    </IonCol>
                    <IonCol className="assign-florist">
                      <h6 className="mb-0" style={{ fontSize: 16 }}>
                        Zone
                      </h6>
                      <h6 style={{ fontSize: 16 }}>{el.deliveryZone && el.deliveryZone}</h6>
                    </IonCol>
                    <IonCol className="assign-florist">
                      <h6 style={{ textAlign: 'center' }} className="mb-0">
                        {el.floristName && el.floristName}
                      </h6>
                      <h6 style={{ textAlign: 'center', color: 'red' }}>
                        {colleagueStatus[el.preparationStatus]}
                      </h6>
                    </IonCol>
                    <IonCol>
                      <ChangeStatusBtn
                        item={el}
                        onSuccess={onStatusChanged}
                        index={i}
                        subIndex={n}
                      />
                    </IonCol>
                  </div>
                ))}
              </IonCardContent>
            </IonCard>
          ))}
        </Fragment>
      ) : (
        <h4 style={{ textAlign: 'center' }}>No orders</h4>
      )}
    </IonGrid>
  );
};

export default BySkuComponent;

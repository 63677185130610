import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IonLoading,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonCol,
} from "@ionic/react";
import { reloadOutline, homeOutline } from "ionicons/icons";
import { reloadPage } from "../../constants/helpers";
import moment from "moment";
//components
import ListOfOrders from "../../components/ordersList/ListOfOrders";
//selectors
import { getorderDetailsData } from "../../store/orders/ordersListSelectors";
//api
import OrdersListService from "../../services/OrdersListService";

const CompletedOrders = ({ history }) => {
  const ordersListData = useSelector((state) => getorderDetailsData({ state })),
    [showLoader, setShowLoader] = useState(false),
    dispatch = useDispatch();

  const fetchCompletedOtrders = async () => {
    setShowLoader(true);
    try {
      const res = await OrdersListService.getCompletedOrders();
      dispatch({
        type: "SET_ORDERS_LIST_DATA",
        payload: {
          ordersList: res.data.data,
          pageCount: 1,
          isFlorist: true,
        },
      });
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchCompletedOtrders();
  }, []);

  return (
    <div>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoader}
        onDidDismiss={() => setShowLoader(false)}
        message={"Please wait..."}
      />
      <IonHeader>
        <IonToolbar style={{ padding: "5px 15px" }}>
          <IonIcon
            onClick={() => history.push("/")}
            slot="start"
            className="home-icons"
            icon={homeOutline}
          ></IonIcon>
          <IonCol
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <h4 style={{ marginLeft: 20 }}>
              {moment(new Date()).format("DD/MM/YYYY")}
            </h4>
          </IonCol>
          <IonIcon
            onClick={() => reloadPage()}
            style={{ marginRight: 13 }}
            slot="end"
            className="home-icons"
            icon={reloadOutline}
          ></IonIcon>
        </IonToolbar>
      </IonHeader>
      <ListOfOrders ordersListData={ordersListData} history={history} />
    </div>
  );
};

export default CompletedOrders;

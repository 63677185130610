import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonIcon,
  IonItem,
  IonPopover,
  IonList,
  IonListHeader,
  IonAlert,
  IonBadge,
} from '@ionic/react';
import { notificationsOutline } from 'ionicons/icons';
import { convertToDateAndTime } from '../../constants/helpers';
//action
import { setReadNotification, setToggleNotificationAlert } from '../../store/app/appActions';
//selectors
import {
  getNotificationsList,
  getNotificationsLength,
  getIsNotificationsAlertOpen,
} from '../../store/app/appSelectors';

const NotificationsComponent = () => {
  const notificationsList = useSelector((state) => getNotificationsList({ state })),
    notificationsLength = useSelector((state) => getNotificationsLength({ state })),
    showAlert = useSelector((state) => getIsNotificationsAlertOpen({ state })),
    [showPopover, setShowPopover] = useState(false),
    dispatch = useDispatch();

  const handleReadNotification = (item) => {
    if (item.read === false) {
      dispatch(setReadNotification(item.id));
    }
  };

  return (
    <Fragment>
      {notificationsList.length > 0 && notificationsList.some((el) => el.read === false) && (
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => dispatch(setToggleNotificationAlert())}
          cssClass="my-custom-class"
          header="Attention!!"
          message={notificationsList.find((el) => el.read === false).content}
          buttons={['OK']}
        />
      )}
      <IonPopover
        cssClass="notification-popover"
        isOpen={showPopover}
        onDidDismiss={(e) => setShowPopover(false)}
      >
        <IonList>
          <IonListHeader className="notifications-list-header">Notifications</IonListHeader>
          {notificationsList.map((item, i) => (
            <IonItem
              onClick={() => handleReadNotification(item)}
              key={i}
              style={{ fontSize: 13 }}
              button
            >
              {item.content} {item.createdAt && convertToDateAndTime(item.createdAt)}
              <img
                alt=""
                src="https://cdn.floward.com/web/Files/attachment/icons8-in_transit-15-637497660015920932.png"
                style={{ width: 25, height: 25, display: item.read ? '' : 'none' }}
              />
            </IonItem>
          ))}
        </IonList>
      </IonPopover>
      <IonBadge className="notification-badge-wrapper" color="danger" slot="end">
        {notificationsList.length >= 0 && (
          <div onClick={() => setShowPopover((prev) => !prev)} className="notification-badge">
            <span style={{ marginRight: 5 }}>{notificationsLength}</span>
          </div>
        )}
      </IonBadge>
      <IonIcon
        onClick={() => setShowPopover((prev) => !prev)}
        slot="end"
        className="home-icons"
        icon={notificationsOutline}
        style={{ marginRight: 10, zIndex: -10 }}
      />
    </Fragment>
  );
};

export default NotificationsComponent;

import React, { useState, Fragment } from 'react';
import Lightbox from 'react-image-lightbox';
import { colleagueStatus } from '../../constants/helpers';
import { star } from 'ionicons/icons';
import { IonCard, IonCol, IonCardContent, IonCardHeader, IonGrid, IonIcon } from '@ionic/react';
import ChangeStatusBtn from './ChangeStatusBtn';

const ListOfOrders = ({
  ordersListData,
  history,
  onStatusChanged,
  initialParams,
  fetchMoreData,
}) => {
  const [isLightBox, setIsLightBox] = useState(false),
    [clickedImg, setClickedImg] = useState('');

  return (
    <IonGrid style={{ backgroundColor: '#dadada' }}>
      {ordersListData.length > 0 ? (
        <Fragment>
          {ordersListData.map((item, i) => (
            <IonCard className="order-card" key={i}>
              <IonCardHeader className="card-header ">
                <IonCol className="assign-florist">
                  <h6 style={{ marginLeft: 10 }} className="mb-0">
                    {' '}
                    Order{' '}
                    {item.orderPriority === 1 && (
                      <span style={{ fontSize: 25, color: 'red' }}>!</span>
                    )}
                  </h6>
                  <h6
                    onClick={() => history.push(`/orderdetails/${item.orderId}`)}
                    style={{ color: 'red', cursor: 'pointer' }}
                  >
                    {item.orderId}
                  </h6>
                  {item.nwDelivery && <div className="nw-order">NW</div>}
                  {item.productionLine === 1 && (
                    <IonIcon
                      className="star-outline"
                      icon={star}
                      style={{
                        color: '#DAA520',
                        fontSize: 22,
                        marginLeft: 18,
                      }}
                    ></IonIcon>
                  )}
                  {item.membershipType === 1 && <div className="vip-order">VIP</div>}
                </IonCol>
                <IonCol>
                  {
                    <h6>
                      Items <span style={{ color: 'red' }}>({item.cartList.length})</span>
                    </h6>
                  }
                </IonCol>
                <IonCol className="assign-florist">
                  <h6 className="mb-0">Zone</h6>
                  <h6>{item.zone && item.zone}</h6>
                </IonCol>
                <IonCol className="assign-florist">
                  <h6 style={{ textAlign: 'center' }} className="mb-0">
                    {item.florist && item.florist.name}
                  </h6>
                  <h6 style={{ textAlign: 'center', color: 'red' }}>
                    {colleagueStatus[item.preparationStatus]}
                  </h6>
                </IonCol>
                <IonCol>
                  <ChangeStatusBtn
                    item={item}
                    fetchMoreData={fetchMoreData}
                    initialParams={initialParams}
                    details={false}
                  />
                </IonCol>
              </IonCardHeader>
              {
                <IonCardContent
                  onClick={() => history.push(`/orderdetails/${item.orderId}`)}
                  className="card-content"
                >
                  {item.cartList.map((el, i) => (
                    <div
                      key={i}
                      style={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                        marginBottom: 15,
                      }}
                    >
                      <img
                        alt={'product'}
                        style={{ width: 80, marginRight: 20 }}
                        src={el.image}
                        onClick={(e) => {
                          e.stopPropagation();
                          setClickedImg(el.image);
                          setIsLightBox(true);
                        }}
                      />
                      <h6
                        style={{
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          color: el.qty > 1 ? 'red' : '',
                        }}
                      >
                        {el.qty} x - {el.sku}
                      </h6>
                    </div>
                  ))}
                </IonCardContent>
              }
            </IonCard>
          ))}
          {isLightBox && (
            <Lightbox mainSrc={clickedImg} onCloseRequest={() => setIsLightBox(false)} />
          )}
        </Fragment>
      ) : (
        <h4 style={{ textAlign: 'center' }}>No orders</h4>
      )}
    </IonGrid>
  );
};

export default ListOfOrders;

import { updateObject } from "../utility";

const initialState = {
  orderDetails: "",
  cartDetails: "",
  inventoryDetails: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ORDER_DETAILS": {
      return updateObject(state, {
        orderDetails: action.payload,
      });
    }
    case "SET_CART_DETAILS": {
      return updateObject(state, {
        cartDetails: action.payload,
      });
    }
    case "SET_INVENTORY_DETAILS": {
      return updateObject(state, {
        inventoryDetails: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;

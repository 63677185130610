/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  IonGrid,
  IonCol,
  IonRow,
  IonInput,
  IonItem,
  IonButton,
  IonLoading,
  IonList,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import microsoftSVG from '../../assets/images/svg/microsoft.svg';
import ErrorMessage from '../shared/ErrorMessage';
import AuthService from '../../services/AuthService';
//actions
import { setIsLoggedInTrue } from './../../store/app/appActions';
import { addToLocalStorage } from './../../helpers/LocalStorage';

const LoginComponent = ({ history }) => {
  const [phone, setPhone] = useState(''),
    [isPhoneValid, setIsPhoneValid] = useState(true),
    [password, setPassword] = useState(''),
    [countryId, setCountryId] = useState(-1),
    [disabled, setDisabled] = useState(false),
    [countriesList, setCountriesList] = useState([]),
    [errorMessage, setErrorMessage] = useState(''),
    [showAlert, setShowAlert] = useState(false),
    [showLoading, setShowLoading] = useState(false),
    dispatch = useDispatch();

  const handleLogin = async () => {
    try {
      setDisabled(true);
      const { data } = await AuthService.logInFlorist({ phone, password, countryId: +countryId });
      if (!data.hasError) {
        validateSession(data.data);
      }
      setDisabled(false);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || 'Something went wrong!');
      setDisabled(false);
      setShowAlert(true);
    }
  };

  const validateSession = async (sessionId) => {
    try {
      let sessionData = await AuthService.validateSession(sessionId);
      addToLocalStorage('token', sessionData.data?.token);
      addToLocalStorage('opsId', sessionData.data?.operationCenter?.id);
      dispatch(setIsLoggedInTrue());
      history.push('/');
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || 'Something went wrong!');
      setShowAlert(true);
    }
  };

  const changePhone = (e) => {
    setPhone(e.target.value);
    const reg = new RegExp('^[0-9]+$');
    if (reg.test(e.target.value)) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
  };

  const handleMicrosoftLogin = () => {
    window.location.href = `${process.env.REACT_APP_MICROSOFT_LOGIN}`;
  };

  const getCountryList = async () => {
    try {
      const res = await AuthService.getCountryCodes();
      setCountriesList(res.data);
    } catch (err) {
      setShowLoading(false);
      setErrorMessage(err?.response?.data?.message ?? 'Something went wrong!');
      setShowAlert(true);
    }
  };

  useEffect(() => {
    getCountryList();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('sessionId');
    if (sessionId) {
      validateSession(sessionId);
    }
    const isFailed = urlParams.get('status');
    if (isFailed === 'failed') {
      // history.back();
      setErrorMessage('Microsoft login has failed');
      setShowAlert(true);
    }
  }, []);

  return (
    <div className="login-wrapper">
      <IonGrid>
        <ErrorMessage
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          errorMessage={errorMessage}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message="Please wait..."
        />
        <IonRow>
          <IonCol>
            <h4 className="login-title">Login</h4>
          </IonCol>
        </IonRow>
        <form>
          <IonRow>
            <IonCol>
              <IonList className="input-wrapper">
                <IonItem>
                  <ion-label style={{ display: 'none' }}>Countries</ion-label>
                  <IonSelect
                    class="select-full-width"
                    placeholder="Select your country"
                    okText="Select country"
                    onIonChange={(e) => {
                      setCountryId(e.target.value);
                    }}
                  >
                    {countriesList?.map((country) => {
                      return (
                        <IonSelectOption key={country.Id} value={country.Id}>
                          +{country.Code}&nbsp;{country.Name}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className={`input-wrapper ${!isPhoneValid ? 'ion-invalid' : ''}`}>
                <IonInput
                  placeholder="Phone number"
                  type="tel"
                  onIonInput={(e) => changePhone(e)}
                  value={phone}
                  inputMode="tel"
                  pattern="^[0-9]+$"
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem className="input-wrapper">
                <IonInput
                  placeholder="Password"
                  type="password"
                  clearInput={true}
                  onIonChange={(e) => setPassword(e.target.value)}
                  value={password}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow style={{ width: '100%' }}>
            <IonCol></IonCol>
          </IonRow>
          <IonRow style={{ width: '100%', paddingTop: 5 }}>
            <IonCol>
              <IonButton
                onClick={handleLogin}
                disabled={!phone || !password || countryId === -1 || disabled}
                class="login-btn"
              >
                Login
              </IonButton>
            </IonCol>
          </IonRow>
          <div>
            <p className="or">OR</p>
          </div>
          <IonRow style={{ width: '100%' }}>
            <IonCol>
              <IonButton onClick={handleMicrosoftLogin} class="login-btn-microsoft" fill="clear">
                <img className="ms-icon" src={microsoftSVG} alt="signIn with MS" />
                Log in With Microsoft
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </IonGrid>
    </div>
  );
};

export default withRouter(LoginComponent);

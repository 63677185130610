import { createBrowserHistory } from 'history';
import moment from 'moment';
export const history = createBrowserHistory();

export const reloadPage = () => {
  window.location.reload();
};

export const handleLogOut = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('opsId');
  window.location.href = '/login';
};

export const convertQueryParamsIntoObject = (params) =>
  JSON.parse(
    '{"' + decodeURI(params.substring(1).replace(/&/g, '","').replace(/=/g, '":"')) + '"}'
  );

export const convertObjectIntoQueryParams = (object) =>
  '?' +
  Object.keys(object)
    .map((key) => `${key}=${object[key]}`)
    .join('&');

export const convertUnixToDate = (unix) => {
  const milliseconds = unix * 1000,
    dateObject = new Date(milliseconds);
  return dateObject;
};

export const handleBack = () => {
  window.history.back();
};

export const status = {
  0: 'Done',
  1: 'Assign',
  2: ['Done', 'Not Done'],
  3: { text: 'Start', enabled: true },
  4: 'Waiting for Picker',
  5: { text: 'Start', enabled: false },
  6: 'Assign',
  7: { text: 'Start', enabled: true },
  8: { text: 'Start', enabled: false },
};

export const colleagueStatus = {
  0: 'Done',
  1: '',
  2: 'Started',
  3: 'Assigned',
  4: 'Waiting for Picker',
  5: 'Assigned',
  6: '',
  7: 'Assigned',
  8: 'Assigned',
};

export const formatDateForInput = (date) => moment(date).format('YYYY-MM-DD');

export const formatDateTime = (date) => `${formatDateForInput(date)}T00:00:00`;

export const convertToDateAndTime = (date) => {
  const dateObject = new Date(date);
  const humanDateFormat = dateObject.toLocaleString();
  return humanDateFormat;
};

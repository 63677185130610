import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OrderDetailsHeader from '../orderDetails/OrderDetailsHeader';
import { IonLoading } from '@ionic/react';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  formatDateTime,
} from '../../constants/helpers';
import ListOfOrders from './ListOfOrders';
import FloristsComponent from './FloristsComponent';
//actionns
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../../store/app/appActions';
//selectors
import {
  getDeliveryData,
  getorderDetailsData,
  getPageCount,
  getDeliveryTimeSlot,
  getOrdersIsSearching,
} from '../../store/orders/ordersListSelectors';
import { getIsLoaderOpen } from '../../store/app/appSelectors';
import FetchOnScroll from '../shared/FetchOnScroll';
//api
import OrdersListService from '../../services/OrdersListService';

const OrderslistComponent = ({ location, history }) => {
  const ordersListData = useSelector((state) => getorderDetailsData({ state })),
    deliveryDate = useSelector((state) => getDeliveryData({ state })),
    deliveryTimeSlot = useSelector((state) => getDeliveryTimeSlot({ state })),
    pageCount = useSelector((state) => getPageCount({ state })),
    [initialParams, setInitialParams] = useState({
      page: 1,
      deliveryDate: formatDateTime(deliveryDate),
      floristId: -1,
      sortby: 3,
      timeName: deliveryTimeSlot,
    }),
    [counter, setCounter] = useState(1),
    isLoaderOpen = useSelector((state) => getIsLoaderOpen({ state })),
    isSearching = useSelector((state) => getOrdersIsSearching({ state })),
    dispatch = useDispatch(),
    queryObj = location.search ? convertQueryParamsIntoObject(location.search) : {};

  const fetchMoreData = async (params, page) => {
    let clonedParams = { ...params, page: counter };
    if (page) {
      setCounter(1);
      clonedParams = { ...params, page: 1 };
    }

    if (page || counter <= pageCount) {
      dispatch(setLoaderOpenTrue());
      try {
        const res = await OrdersListService.getordersListData(clonedParams, clonedParams.slotId);
        dispatch({
          type: 'SET_ORDERS_LIST_DATA',
          payload: {
            ordersList: res.data.data,
            pageCount: res.data.pageCount,
            isFlorist: page ? true : false,
          },
        });
        history.push({
          pathname: '/orders',
          search: convertObjectIntoQueryParams(clonedParams),
        });
        setCounter((prev) => prev + 1);
        dispatch(setLoaderOpenFalse());
      } catch (err) {
        dispatch(setLoaderOpenFalse());
      }
    }
  };

  const fetchData = () => {
    setInitialParams(queryObj);
    return fetchMoreData(queryObj);
  };

  const onStatusChanged = (index, newStatus) => {
    var items = [...ordersListData];
    items[index].preparationStatus = newStatus;
    dispatch({
      type: 'SET_ORDERS_LIST_DATA',
      payload: items,
    });
  };

  return (
    <div style={{ backgroundColor: '#dadada' }}>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={isLoaderOpen}
        onDidDismiss={() => dispatch(setLoaderOpenFalse())}
        message={'Please wait...'}
      />
      <OrderDetailsHeader
        initialParams={initialParams}
        history={history}
        slotId={queryObj.slotId}
        deliveryTimeSlot={deliveryTimeSlot}
      />
      <FloristsComponent
        setCounter={setCounter}
        initialParams={initialParams}
        slotId={queryObj.slotId}
      />
      <FetchOnScroll fetchApiData={isSearching ? () => {} : fetchData}>
        <ListOfOrders
          ordersListData={ordersListData}
          onStatusChanged={onStatusChanged}
          history={history}
          initialParams={initialParams}
          fetchMoreData={fetchMoreData}
        />
      </FetchOnScroll>
    </div>
  );
};

export default withRouter(OrderslistComponent);

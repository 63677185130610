import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IonButton } from '@ionic/react';
//actions
import { setOrdersListData } from '../../store/orders/ordersListActions';
//api
import OrdersListService from '../../services/OrdersListService';

const FloristsComponent = ({ initialParams, match, setCounter, slotId }) => {
	const [floristsList, setFloristsList] = useState([]),
		[active, setActive] = useState(true),
		dispatch = useDispatch();

	const fetchFlorsits = async () => {
		try {
			const res = await OrdersListService.getFloristsList();
			setFloristsList(res.data);
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(() => {
		fetchFlorsits();
	}, []);

	useEffect(() => {
		floristsList.forEach((item) => (item.acive = false));
	}, [floristsList]);

	const handleFloristOrders = (floristId) => {
		dispatch({
			type: 'SET_ORDERS_LIST_DATA',
			payload: {
				ordersList: [],
				pageCount: 1,
				isFlorist: true,
			},
		});

		floristsList.forEach((item) => (item.active = false));
		if (floristId != -1) {
			floristsList.find((item) => item.id == floristId).active = true;
			setActive(false);
		} else {
			setActive(true);
		}
		setCounter(1);
		dispatch(setOrdersListData({ ...initialParams, page: 1, floristId: floristId }, slotId));
	};

	return (
		<div style={{ textAlign: 'center', margin: '10px 0' }}>
			<IonButton onClick={() => handleFloristOrders(-1)} color={active ? 'primary' : 'light'}>
				All
			</IonButton>
			{floristsList && (
				<Fragment>
					{floristsList.map((item, i) => (
						<IonButton
							key={i}
							onClick={() => handleFloristOrders(item.id)}
							color={item.active ? 'primary' : 'light'}
						>
							{item.name}
						</IonButton>
					))}
				</Fragment>
			)}
		</div>
	);
};

export default withRouter(FloristsComponent);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import OrderslistComponent from '../../components/ordersList/OrderslistComponent';
import './orders-list.scss';

const OrdersList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_ORDERS_LIST_DATA',
        payload: {
          ordersList: [],
          pageCount: 1,
          isFlorist: true,
        },
      });
    };
  }, []);

  return (
    <Fragment>
      <OrderslistComponent />
    </Fragment>
  );
};

export default OrdersList;

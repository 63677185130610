export const getDeliveryData = ({ state }) => state.ordersList.deliveryDate;
export const getDeliveryTimeSlot = ({ state }) => state.ordersList.deliveryTimeSlot;

export const getorderDetailsData = ({ state }) => state.ordersList.ordersListData;

export const getPageCount = ({ state }) => state.ordersList.pageCount;

export const getOrdersIsSearching = ({ state }) => state.ordersList.isSearching;

export const getGroupBySkuData = ({ state }) => state.ordersList.groupBySku;

export const getGroupByZoneData = ({ state }) => state.ordersList.groupByZone;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  IonHeader,
  IonToolbar,
  IonIcon,
  IonButton,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonCard,
  IonDatetime,
  IonRow,
  IonItem,
  IonLabel,
  IonLoading,
  IonSearchbar,
} from '@ionic/react';
import { logOutOutline, reloadOutline, star } from 'ionicons/icons';
import {
  reloadPage,
  handleLogOut,
  convertObjectIntoQueryParams,
  formatDateTime,
} from '../../constants/helpers';
import moment from 'moment';
import NotificationsComponent from '../../components/shared/NotificationsComponent';
import { formatDateForInput } from '../../constants/helpers';
//selectors
import { getDeliveryData } from '../../store/orders/ordersListSelectors';
//api
import TimeSlotsService from '../../services/TimeSlotsService';
import { setIsSearchingTrue, setOrdersListData } from '../../store/orders/ordersListActions';

const HomeComponent = ({ history }) => {
  const [searchText, setSearchText] = useState(''),
    deliveryDate = useSelector((state) => getDeliveryData({ state })),
    [timeSlots, setTimeSlots] = useState([]),
    [showLoader, setShowLoader] = useState(false),
    dispatch = useDispatch();

  const fetchTimeSlots = async (date) => {
    const params = {
      deliverydate: formatDateForInput(date),
    };
    setShowLoader(true);
    try {
      const res = await TimeSlotsService.getTimeSlotsByDate(params);
      setTimeSlots(res.data[0].slots);
      setShowLoader(false);
    } catch (err) {
      setTimeSlots([]);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchTimeSlots(deliveryDate);
  }, []);

  const handleDeliveryDate = (e) => {
    fetchTimeSlots(moment(e.target.value));
    dispatch({
      type: 'SET_DELIVERY_DATE',
      payload: new Date(e.target.value),
    });
  };

  const handleOrdersListPage = (item) => {
    dispatch({
      type: 'SET_DELIVERY_TIME_SLOT',
      payload: `${item.deliveryTimeName} (${item.nickName})`,
    });
    history.push({
      pathname: `/orders`,
      search: convertObjectIntoQueryParams({
        slotId: item.slotId,
        sortby: 3,
        deliveryDate: formatDateTime(deliveryDate),
        floristId: -1,
        searchKey: '',
      }),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setIsSearchingTrue());
    dispatch(
      setOrdersListData(
        {
          sortby: -1,
          deliveryDate: formatDateTime(deliveryDate),
          floristId: -1,
          searchKey: searchText,
        },
        -1
      )
    );
  };

  return (
    <Fragment>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoader}
        onDidDismiss={() => setShowLoader(false)}
        message={'Please wait...'}
      />
      <IonHeader>
        <IonToolbar style={{ padding: '5px 15px' }}>
          <IonButton
            onClick={() => history.push('/completed-orders')}
            style={{ padding: '5px 0' }}
            color="primary"
          >
            Completed Orders
          </IonButton>
          <form className="search-form" onSubmit={(e) => handleSubmit(e)}>
            <IonSearchbar
              className="ion-search-bar"
              value={searchText}
              onIonChange={(e) => setSearchText(e.detail.value)}
              placeholder="Search by order ID or SKU"
            />
          </form>
          <NotificationsComponent />

          <IonIcon
            onClick={() => reloadPage()}
            style={{ marginRight: 13 }}
            slot="end"
            className="home-icons"
            icon={reloadOutline}
          />
          <IonIcon
            onClick={() => handleLogOut()}
            slot="end"
            className="home-icons"
            icon={logOutOutline}
          />
        </IonToolbar>
      </IonHeader>
      {!showLoader && (
        <IonRow style={{ margin: 10 }}>
          <IonItem style={{ height: 45 }} className="input-wrapper">
            <IonLabel style={{ fontSize: 15, color: '#0c697e', fontWeight: 'bold' }}>
              Delivery date
            </IonLabel>
            <IonDatetime
              style={{ color: ' black' }}
              value={deliveryDate.toISOString()}
              placeholder="Select Date"
              onIonChange={handleDeliveryDate}
              min={new Date().toISOString()}
              max={'2030'}
            />
          </IonItem>
        </IonRow>
      )}

      {timeSlots.length > 0 && !showLoader && (
        <div>
          {timeSlots.map((item, i) => (
            <IonCard onClick={() => handleOrdersListPage(item)} key={i} button={true}>
              <IonCardHeader>
                <IonCardTitle
                  style={{
                    fontSize: 18,
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                >
                  {item.deliveryTimeName} ({item.nickName.toUpperCase()}){' '}
                  {item.productionLine === 1 && (
                    <IonIcon
                      className="star-outline"
                      icon={star}
                      style={{
                        color: '#DAA520',
                        fontSize: 22,
                        marginLeft: 4,
                      }}
                    />
                  )}
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                {item.collecting} Collecting - {item.orders} Ordered - {item.preparing} Preparing
              </IonCardContent>
            </IonCard>
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default withRouter(HomeComponent);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Redirect, Route, Router } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IonApp } from '@ionic/react';
import jwt_decode from 'jwt-decode';
import { getFromLocalStorage } from './helpers/LocalStorage';
import { handleLogOut } from './constants/helpers';
import 'react-image-lightbox/style.css';
//pages
import Home from './pages/home/Home.jsx';
import Login from './pages/login/Login.jsx';
import OrdersList from './pages/ordersList/OrdersList.jsx';
import OrderDetails from './pages/orderDetails/OrderDetails.jsx';
import CompletedOrders from './pages/completedOrders/CompletedOrders.js';
import GroupedBySku from './pages/groupedBySku/GroupedBySku';
import GroupedByZone from './pages/groupedByZone/GroupedByZone';
//actions
import { setNotificationsList, setToggleNotificationAlert } from './store/app/appActions';
//selectors
import { getIsLoggedIn } from './store/app/appSelectors';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { history } from './constants/helpers';

const App = () => {
  const isLoggedIn = useSelector((state) => getIsLoggedIn({ state })),
    dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNotificationsList());
    const token = getFromLocalStorage('token');
    if (token) {
      const decoded: any = jwt_decode(token);
      if (decoded?.accessId) {
        handleLogOut();
      }
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(setNotificationsList());
      dispatch(setToggleNotificationAlert());
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  return (
    <IonApp style={{ backgroundColor: '#dadada' }}>
      <Router history={history}>
        <Route exact path="/home" component={isLoggedIn ? Home : Login} />
        <Route exact path="/" render={() => <Redirect to="/home" />} />
        <Route exact path="/login" component={isLoggedIn ? Home : Login} />
        <Route exact path="/orders" component={isLoggedIn ? OrdersList : Login} />
        <Route exact path="/orderdetails/:orderId" component={isLoggedIn ? OrderDetails : Login} />
        <Route exact path="/completed-orders" component={isLoggedIn ? CompletedOrders : Login} />
        <Route exact path="/groupedBySKU" component={isLoggedIn ? GroupedBySku : Login} />
        <Route exact path="/groupedByZone" component={isLoggedIn ? GroupedByZone : Login} />
      </Router>
    </IonApp>
  );
};

export default App;

import React, { Fragment, useState } from 'react';
import Lightbox from 'react-image-lightbox';

const InventoryTable = ({ inventoryData }) => {
	const [isLightBox, setIsLightBox] = useState(false),
		[clickedImg, setClickedImg] = useState('');
	return (
		<Fragment>
			<div
				style={{
					width: '100%',
					textAlign: 'center',
					height: 500,
				}}
				className="table-responsive shopping-table "
			>
				<table style={{ width: '100%' }} className="table table-bordernone">
					<thead>
						<tr>
							<th scope="col">Item</th>
							<th scope="col">Name</th>
							<th scope="col">Barcode</th>
							<th scope="col">Qty</th>
						</tr>
					</thead>
					<tbody>
						{inventoryData &&
							inventoryData.map((el, n) => (
								<Fragment key={n}>
									<tr>
										<td>
											<img
												onClick={() => {
													setClickedImg(el.image);
													setIsLightBox(true);
												}}
												src={el.image}
												style={{ width: 70 }}
											/>
										</td>
										<td>{el.name}</td>
										<td>{el.barcode}</td>
										<td>{el.qty}</td>
									</tr>
									{isLightBox && (
										<Lightbox mainSrc={clickedImg} onCloseRequest={() => setIsLightBox(false)} />
									)}
								</Fragment>
							))}
					</tbody>
				</table>
			</div>
		</Fragment>
	);
};

export default InventoryTable;

import React from "react";
import {IonGrid} from "@ionic/react";
import LoginComponent from "../../components/login/LoginComponent"
import "./login.scss";

const Login = () => {
  return (
    <div className="login-wrapper">
      <IonGrid>
        <LoginComponent />
      </IonGrid>
    </div>
  );
};

export default Login;

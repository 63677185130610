/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BasketModal from './BasketModal';
import {
  IonCardHeader,
  IonCardContent,
  IonCard,
  IonRow,
  IonLoading,
  IonCol,
  IonButton,
  IonGrid,
  IonContent,
} from '@ionic/react';
//components
import OrderDetailsHeader from './OrderDetailsHeader';
import ProductDetails from './ProductDetails';
import FavoriteFlowers from './FavoriteFlowers';
//actions
import { setOrderDetailsAllData } from '../../store/orderDetails/orderDetailsActions';
import { setLoaderOpenFalse } from '../../store/app/appActions';
//selectors
import { getOrderDetails, getCartDetails } from '../../store/orderDetails/orderDetailsSelectors';
import { getIsLoaderOpen } from '../../store/app/appSelectors';

const OrderDetailsComponent = ({ match, history }) => {
  const orderDetails = useSelector((state) => getOrderDetails({ state })),
    cartDetails = useSelector((state) => getCartDetails({ state })),
    isLoaderOpen = useSelector((state) => getIsLoaderOpen({ state })),
    [isModalOpen, setIsOpenModal] = useState(false),
    [basketId, setBasketId] = useState(''),
    dispatch = useDispatch();

  const handleCartDetails = (id) => {
    setBasketId(id);
    setIsOpenModal((prev) => !prev);
  };

  const fetchOrderDetails = (orderId) => {
    dispatch(setOrderDetailsAllData(orderId));
  };

  useEffect(() => {
    fetchOrderDetails(match.params.orderId);
  }, []);

  return (
    <Fragment>
      <OrderDetailsHeader
        orderDetails={orderDetails}
        details={true}
        history={history}
        fetchData={fetchOrderDetails}
      />
      <IonContent>
        <IonGrid>
          {isModalOpen && (
            <BasketModal
              isModalOpen={isModalOpen}
              setIsOpenModal={setIsOpenModal}
              basketId={basketId}
            />
          )}
          <IonLoading
            cssClass="my-custom-class"
            isOpen={isLoaderOpen}
            onDidDismiss={() => dispatch(setLoaderOpenFalse())}
            message={'Please wait...'}
          />
          {cartDetails !== '' &&
            cartDetails.map((item, i) => (
              <Fragment key={i}>
                <IonCard style={{ padding: 15, overflow: 'scroll' }}>
                  <IonCardHeader style={{ padding: 0 }}>
                    <IonRow style={{ display: 'flex', alignItems: 'center' }}>
                      <IonCol>
                        {item.name && (
                          <h6 className="sku">
                            {item.name} {item.sku && `(${item.sku})`}
                          </h6>
                        )}
                        {item.cakeMessage && (
                          <h6 className="sku">Cake message: {item.cakeMessage}</h6>
                        )}
                        {item.qty && (
                          <h6 className="sku" style={{ color: '#DC143C' }}>
                            {' '}
                            Qty: {item.qty}
                          </h6>
                        )}
                      </IonCol>
                      <IonCol>
                        <IonButton onClick={() => handleCartDetails(item.id)} color="primary">
                          Show Basket Details
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow>
                      <ProductDetails proId={item.productId} />
                    </IonRow>
                  </IonCardContent>
                  <IonCardContent>
                    <IonRow>
                      <FavoriteFlowers cartId={item.id} />
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </Fragment>
            ))}
        </IonGrid>
      </IonContent>
    </Fragment>
  );
};

export default withRouter(OrderDetailsComponent);

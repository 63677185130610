import { IonGrid, IonAlert } from '@ionic/react';

const ErrorMessage = ({ errorMessage, showAlert, setShowAlert }) => {
  return (
    <IonGrid>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="my-custom-class"
        message={errorMessage}
        buttons={['OK']}
      />
    </IonGrid>
  );
};

export default ErrorMessage;

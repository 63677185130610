import cloneDeep from 'lodash/cloneDeep';
//api
import NotificationsService from '../../services/NotificationsService';
//selectors
import { getNotificationsList } from './appSelectors';

export const setIsLoggedInTrue = () => ({ type: 'SET_IS_LOGGED_IN_TRUE' });
export const setIsLoggedInFalse = () => ({ type: 'SET_IS_LOGGED_IN_FALSE' });

export const setLoaderOpenTrue = () => ({ type: 'SET_LOADER_OPEN_TRUE' });
export const setLoaderOpenFalse = () => ({ type: 'SET_LOADER_OPEN_FALSE' });

export const setNotificationsList = () => async (dispatch) => {
	try {
		const res = await NotificationsService.getNotificationsList();
		const notificationsLength = res.data.data.filter((el) => el.read === false).length;
		dispatch({
			type: 'SET_NOTIFICATIONS_LIST',
			payload: res.data.data,
		});
		dispatch({
			type: 'SET_NOTIFICATIONS_LENGTH',
			payload: notificationsLength,
		});
	} catch (e) {
		console.log(e);
	}
};

export const setReadNotification = (id) => async (dispatch, getState) => {
	const state = getState(),
		notificationsList = cloneDeep(getNotificationsList({ state }));
	try {
		await NotificationsService.getReadNotification(id);
		notificationsList.find((item) => item.id === id).read = true;
		const notificationsLength = notificationsList.filter((el) => el.read === false).length;
		dispatch({
			type: 'SET_NOTIFICATIONS_LIST',
			payload: notificationsList,
		});
		dispatch({
			type: 'SET_NOTIFICATIONS_LENGTH',
			payload: notificationsLength,
		});
	} catch (e) {
		console.log(e);
	}
};

export const setToggleNotificationAlert = () => async (dispatch) => {
	dispatch({
		type: 'SET_TOGGLE_NOTIFICATIONS_ALERT',
	});
};

// const CryptoJS = require('crypto-js');

export const addToLocalStorage = (key, value) => {
  // const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), 'secret').toString();
  localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key) => {
  const decryptedValue = localStorage.getItem(key);
  return decryptedValue;
  // if (decryptedValue) {
  //   const bytes = CryptoJS.AES.decrypt(decryptedValue, 'secret');
  //   const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // return decryptedData;
  // }
  // return '';
};

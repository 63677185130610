import React, { Fragment, useState } from 'react';
import { IonLoading, IonButton } from '@ionic/react';
import { status } from '../../constants/helpers';
//api
import OrdersListService from '../../services/OrdersListService';

const ChangeStatusBtn = ({ item, initialParams, fetchMoreData, details }) => {
  const [isLoaderOpen, setIsloaderOpen] = useState(false);

  const fetchData = () => {
    if (details) {
      fetchMoreData(item.orderId);
    } else {
      fetchMoreData(initialParams, 1);
    }
  };
  const handleChangeStatus = async (item) => {
    setIsloaderOpen(true);
    try {
      if (item.preparationStatus === 3 || item.preparationStatus === 7) {
        await OrdersListService.assignFloristStatus(item.orderId, 'start');
        setTimeout(() => fetchData(), 1000);
      } else if (item.preparationStatus === 2) {
        await OrdersListService.assignFloristStatus(item.orderId, 'finish');
        setTimeout(() => fetchData(), 500);
      } else if (
        !item.preparationStatus ||
        item.preparationStatus === 6 ||
        item.preparationStatus === 1
      ) {
        await OrdersListService.assignFloristStatus(item.orderId, 'assign');
        setTimeout(() => fetchData(), 1000);
      }
      setIsloaderOpen(false);
    } catch (err) {
      console.log(err);
      setIsloaderOpen(false);
    }
  };

  const handleNotDone = async () => {
    setIsloaderOpen(true);
    try {
      await OrdersListService.assignFloristStatus(item.orderId, 'NotDone');
      setTimeout(() => fetchData(), 1000);
      setIsloaderOpen(false);
    } catch (err) {
      setIsloaderOpen(false);
    }
  };
  return (
    <Fragment>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={isLoaderOpen}
        onDidDismiss={() => setIsloaderOpen(false)}
        message={'Please wait...'}
      />
      {typeof status[item.preparationStatus ?? 6] === 'string' ? (
        <IonButton
          onClick={() => handleChangeStatus(item)}
          style={{
            display: item.preparationStatus === 0 ? 'none' : '',
            marginRight: 15,
          }}
          color={item.preparationStatus === 0 ? 'success' : 'primary'}
          disabled={item.preparationStatus === 4}
        >
          {status[item.preparationStatus ?? 6]}
        </IonButton>
      ) : Array.isArray(status[item.preparationStatus ?? 6]) ? (
        status[item.preparationStatus ?? 6].map((s) => {
          return (
            <IonButton
              onClick={s === 'Done' ? () => handleChangeStatus(item) : () => handleNotDone(item)}
              style={{
                display: item.preparationStatus === 0 ? 'none' : '',
                marginRight: 15,
              }}
              color={s === 'Done' ? 'success' : 'danger'}
            >
              {s}
            </IonButton>
          );
        })
      ) : (
        <IonButton
          onClick={() => handleChangeStatus(item)}
          style={{
            display: item.preparationStatus === 0 ? 'none' : '',
            marginRight: 15,
          }}
          color={'secondary'}
          disabled={!status[item.preparationStatus ?? 6].enabled}
        >
          {status[item.preparationStatus ?? 6].text}
        </IonButton>
      )}

      {/* <IonButton
        color="danger"
        onClick={() => handleNotDone(item)}
        style={{
          display: item.preparationStatus != 2 ? 'none' : '',
        }}
      >
        Not Done
      </IonButton> */}
    </Fragment>
  );
};

export default ChangeStatusBtn;

import React, {useEffect} from 'react';
import {  useDispatch } from "react-redux";
import OrderDetailsComponent from "../../components/orderDetails/OrderDetailsComponent"
//scss
import "./order-details.scss"

const OrderDetails = () => {
    const dispatch = useDispatch()
    useEffect(() => {
		return () => {
		    dispatch({
                type: "SET_ORDER_DETAILS",
                payload: "",
              });
              dispatch({
                type: "SET_CART_DETAILS",
                payload: "",
              });
		};
	}, []);
    return (
            <OrderDetailsComponent />
    );
};

export default OrderDetails;
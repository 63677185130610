import axios from 'axios';
import { getFromLocalStorage } from '../../helpers/LocalStorage';

const createNewBaseUrl = (url) =>
  axios.create({
    baseURL: url,
  });

const apiQuerytaskService = createNewBaseUrl(process.env.REACT_APP_QUERYTASK),
  apiGatewayService = createNewBaseUrl(process.env.REACT_APP_API_GATEWAY);

const requestInterceptor = (config) => {
  config.headers['opsId'] = getFromLocalStorage('opsId');
  config.headers['Authorization'] = `Bearer ${getFromLocalStorage('token')}`;

  return config;
};

const requestInterceptorError = (error) => {
  return Promise.reject(error);
};

const responseInterceptor = (response) => {
  return response;
};

const responseInterceptorError = (error) => {
  const errorResponse = error.response;

  return Promise.reject(error);
};

const addInterceptors = (service) => {
  service.interceptors.request.use(requestInterceptor, requestInterceptorError);
  service.interceptors.response.use(responseInterceptor, responseInterceptorError);
};

addInterceptors(apiQuerytaskService);
addInterceptors(apiGatewayService);

export { apiQuerytaskService, apiGatewayService };

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IonLoading } from "@ionic/react";
//actions
import { setLoaderOpenFalse } from "../../store/app/appActions";
//selectors
import { getIsLoaderOpen } from "../../store/app/appSelectors";
import { getPageCount } from "../../store/orders/ordersListSelectors";

const FetchOnScroll = ({ fetchApiData, children }) => {
  const scrollableArea = React.useRef(),
    isLoaderOpen = useSelector((state) => getIsLoaderOpen({ state })),
    pageCount = useSelector((state) => getPageCount({ state })),
    dispatch = useDispatch();

  useEffect(() => {
    fetchApiData();
  }, []);

  const loadMoreData = () => {
    const { current } = scrollableArea;
    if (
      current.scrollTop > current.scrollHeight - current.offsetHeight - 20 &&
      pageCount > 1
    ) {
      if (!isLoaderOpen) {
        fetchApiData();
      }
    }
  };

  return (
    <div className='fetch-on-scroll-data-wrapper'>
      <IonLoading
        cssClass='my-custom-class'
        isOpen={isLoaderOpen}
        onDidDismiss={() => dispatch(setLoaderOpenFalse())}
        message={"Please wait..."}
      />
      <div
        ref={scrollableArea}
        className='fetch-on-scroll-data'
        onScroll={loadMoreData}>
        {children}
      </div>
    </div>
  );
};

export default FetchOnScroll;
